.wrapper {
  height: 100px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.bottomText {
  font-family: 'Yuji Syuku', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  position: absolute;
  right: 12px;
  bottom: 9px;
  z-index: 2;
}

@media (max-width: 600px) {
  .wrapper {
    height: 40px;
  }
  .bottomText {
    font-size: 7px;
  }
}
