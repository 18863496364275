.wrapper {
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 350px);
}

.item {
  width: 100%;
  height: 69px;
  background: rgba(44, 34, 38, 0.5);

  border-radius: 0 0 17px 17px;

  font-family: "Yuji Syuku", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #ffffff;
  cursor: pointer;

  opacity: 0.75;
  box-shadow: 0 16px 4px rgba(0, 0, 0, 0.25);
  transition: 300ms;
}

.item:first-child {
  border-radius: 0 0 17px 37px;
}

.item:last-child {
  border-radius: 0 0 37px 17px;
}

.item.selected,
.item:hover {
  opacity: 1;

  transition: 300ms;
}

.item.selected {
  background: rgba(148, 70, 67, 0.15);

  transition: 100ms;
}

@media (max-width: 1450px) {
  .wrapper {
    width: 100%;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
  }

  .item {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .item {
    height: 40px;
    font-size: 13px;

    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  .item:first-child {
    border-radius: 0 0 17px 17px;
  }

  .item:last-child {
    border-radius: 0 0 17px 17px;
  }
}

@media (max-width: 416px) {
  .item {
    height: 20px;
    font-size: 7px;

    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 10px 10px;
  }

  .item:first-child {
    border-radius: 0 0 10px 10px;
  }

  .item:last-child {
    border-radius: 0 0 10px 10px;
  }
}
