.wrapper {
  width: 100%;
  justify-content: end;
  height: 51px;
}

.menuSwitcher {
  position: relative;
  top: 10px;
  z-index: 10;
  overflow: auto;
  height: 100%;
  display: grid;
  align-content: center;
  align-items: center;
  justify-self: end;
  align-self: end;
}

.mobileWrapper {
  width: 100vw;
  height: 100%;
  position: fixed;
  left: 100vw;
  top: 0;

  background-color: black;
  opacity: 0;
  transition: left 0.6s, opacity 0.3s;
  display: grid;
  align-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  justify-content: center;
  justify-items: center;
  z-index: 5;
}

.mobileWrapper.active {
  left: 0;
  top: 0;
  opacity: 1;
  transition: left 0.6s, opacity 0.3s;
}

.mobileWrapper > nav {
  width: 100%;
  height: 100%;
  margin-top: 40px;
  display: grid;
  align-content: start;
  row-gap: 30px;
  justify-content: center;
  justify-items: center;
}

.mobileWrapper > nav > a {
  text-decoration: none;
}

.mobileWrapper > nav > a > span {
  font-family: "Yuji Syuku", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 29px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  cursor: pointer;
}

.messengersWrapper {
  display: grid;
  grid-auto-flow: column;
  column-gap: 15px;
  align-content: center;
  align-items: center;
}

.bottomBlock {
  display: grid;
  row-gap: 20px;
}
