.wrapper {
  display: grid;
  justify-content: center;
  padding: 70px 0;
  background: #181F28;
  box-shadow: 0 20px 18px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  width: 100%;
  max-width: 1400px;
  margin-top: 40px;
}

.wrapper > p {
  font-family: 'Yuji Syuku', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 196.02%;

  letter-spacing: 0.03em;

  color: #FFFFFF;
  margin: 0;
  padding: 0 100px;
  white-space: pre-wrap;
}

@media (max-width: 650px) {
  .wrapper {
    padding: 0;
    background: unset;
    box-shadow: unset;
  }

  .wrapper > p {
    font-family: 'Yuji Syuku', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 196.02%;
    padding: 0;
    width: 100%;
    text-align: center;
  }
}
