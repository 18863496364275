.wrapper {
  display: grid;
  row-gap: 50px;
  align-content: start;
  padding-top: 90px;
}

.characterWrapper {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  column-gap: 80px;
  align-items: center;

  background: #181F28;
  box-shadow: 0 20px 18px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  padding: 50px;
  box-sizing: border-box;
}

.characterWrapper > img {
  width: 350px;
  height: 350px;
  object-fit: cover;
  border-radius: 100px;
}

.characterWrapper > p {
  padding: 0 50px 0 0;
  margin: 0;

  font-family: 'Yuji Syuku', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  text-align: start;

  color: #FFFFFF;
  white-space: pre-wrap;
}

@media (max-width: 1200px) {
  .characterWrapper {
    grid-auto-flow: unset;
    justify-items: center;
    row-gap: 50px;
  }

  .characterWrapper > p {
    padding: 0;
    text-align: center;
  }

  .characterWrapper > img {
    max-width: calc(100% - 50px);
    height: auto;
  }
}

@media (max-width: 650px) {
  .wrapper {
    padding-top: 50px;
  }

  .characterWrapper {
    border-radius: 25px;
    padding: 30px;
  }

  .characterWrapper > p {
    padding: 0 0 0 0;
    font-size: 10px;
    line-height: 20px;
    color: #FFFFFF;
  }
}

@media (max-width: 300px) {
  .characterWrapper > img {
    border-radius: 15px;
  }

  .characterWrapper > p {
    font-size: 8px;
    line-height: 15px;
  }

  .characterWrapper {
    row-gap: 20px;
  }
}
