.wrapper {
  height: 102px;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 45px 0 22px;
  position: absolute;
  z-index: 5;
}

@media (max-width: 660px) {
  .wrapper {
    justify-content: end;
    height: 51px;
    padding: 0 15px 0 0;
    overflow: hidden;
    position: fixed;
  }

  .wrapper > svg {
    width: 25px;
  }
}
