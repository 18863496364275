.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  column-gap: 50px;
  row-gap: 50px;
  padding-top: 90px;
}

.image {
  width: 100%;
  border-radius: 50px;
  cursor: pointer;

  transition: 300ms;
  overflow: hidden;
}

.image:hover {
  transform: scale(1.05);

  transition: 140ms;
}

@media (max-width: 1200px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 700px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 300px) {
  .wrapper > img {
    border-radius: 30px;
  }
}
