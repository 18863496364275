body {
  background-color: black;
  margin: 0;
  font-family: "Yuji Syuku", serif;
}

@font-face {
  font-family: "Yuji Syuku";
  src: url("/public/fonts/TLE.ttf");
}
