.navWrapper {
  display: grid;
  grid-auto-flow: column;

  align-content: center;
  align-items: center;
  justify-content: start;
  column-gap: 19px;
}

.navWrapper > nav {
  display: grid;
  grid-auto-flow: column;
  column-gap: 38px;
}

.navWrapper > nav > a {
  text-decoration: none;
  opacity: 0.6;
}

.navWrapper > nav > a > span {
  font-family: "Yuji Syuku", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
}

.messengersWrapper {
  display: grid;
  grid-auto-flow: column;
  column-gap: 20px;
  align-items: center;
}

.messengersWrapper > svg {
  cursor: pointer;
}

.messengersWrapper a:hover path {
  fill: red;
  transition: fill 200ms;
}

.navWrapper > nav > a.active {
  opacity: 1;
}

.rightBlock {
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  column-gap: 35px;
}
