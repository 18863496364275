.language {
  margin-bottom: 10px;
}

.language :global(.ant-tabs-nav) {
  margin: 0;
}

.language :global(.ant-tabs-nav)::before {
  border-bottom: 1px solid transparent;
}

.language :global(.ant-tabs-ink-bar) {
  background-color: red;
}

.language :global(.ant-tabs-tab-btn) {
  color: white;
}

.language :global(.ant-tabs-tab-active) :global(.ant-tabs-tab-btn) {
  color: red !important;
}

@media (max-width: 650px) {
  .language :global(.ant-tabs-tab-btn) {
    font-size: 17px;
  }
}
