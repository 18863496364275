.wrapper {
  position: relative;
  background: linear-gradient(269.97deg, #101720 59.36%, #070C16 83.82%);
  padding-bottom: 100px;
  display: grid;
  justify-content: center;
  justify-items: center;
  width: 100%;
  align-content: start;
  grid-template-columns: 100%;
}

.wrapper > *:first-child {
  position: relative;
  z-index: 2;
}

.bgImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  z-index: 1;
  opacity: 0.2;
}

.contentWrapper {
  max-width: 1400px;
  width: 100%;
  z-index: 2;

  padding: 0 50px;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .wrapper {
    padding-bottom: 50px;
  }
}
