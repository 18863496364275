.wrapper {
  width: 100%;
  min-height: 100vh;
  background-image: url("/public/images/Background3.png");
  background-position: center top;
  background-size: cover;

  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: start;
  row-gap: 50px;
}

.infoBlock {
  display: grid;
  align-content: start;
  justify-items: center;
}

.infoBlock > p {
  font-family: "Yuji Syuku", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 196.02%;

  letter-spacing: 0.03em;

  color: #ffffff;
  width: 100%;
  max-width: 925px;
  white-space: pre-wrap;
  margin: 10px 0;
}

.infoBlock > p:first-child {
  margin-top: 264px;
}

.infoBlock a {
  color: white;
  text-decoration: unset;

  transition: color 350ms;
}

.infoBlock a:hover {
  color: red;
}

.fondImage {
  padding: 10px;
  background: white;
  border-radius: 10px;
  width: 200px;
  margin-bottom: 50px;
}

@media (max-width: 1200px) {
  .wrapper {
    row-gap: 40px;
  }

  .infoBlock p {
    width: calc(100% - 60px);
    max-width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  .infoBlock p:first-child {
    margin-top: 100px;
  }
}

@media (max-width: 800px) {
  .infoBlock p {
    font-size: 15px;
    line-height: 150%;
  }
}

@media (max-width: 430px) {
  .infoBlock p {
    font-size: 13px;
    line-height: 140%;
  }
}
