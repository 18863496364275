.wrapper {
  background-image: url("/public/images/Background.png");
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
}

.videoWrapper {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-columns: 100%;
  row-gap: 60px;
  position: absolute;
  bottom: 50px;
  right: 106px;
  width: 820px;
}

.videoWrapper > h1 {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-family: "Yuji Syuku", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: #ffffff;
}

.videoBgWrapper {
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 20px 10px rgba(0, 0, 0, 0.25);
  border-radius: 132px;
  cursor: pointer;
  width: 100%;
  display: grid;
  place-content: center;
  transition: transform 300ms;
}

@media (hover: hover) {
  .videoBgWrapper:hover {
    box-shadow: 0 0 9px 4px #0e151f;
    animation: glow 2s linear infinite alternate;
    transform: scale(1.03);
    transition: transform 300ms;
  }
}

.videoBgWrapper > img {
  width: 100%;
}

.svgWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  place-content: center;
  opacity: 0.8;
  transition: opacity 300ms;
}

.videoBgWrapper:hover > .svgWrapper {
  opacity: 1;
  transition: opacity 300ms;
}

.modal :global(.ant-modal-content) {
  padding: 0;
  background: black;
}

.iframe {
  width: 900px;
  height: unset !important;
  aspect-ratio: 1.78 / 1;
  transform: scale(1.051);
}

@media (max-width: 1880px) {
  .videoWrapper {
    width: 500px;
    row-gap: 10px;
  }

  .videoBgWrapper {
    border-radius: 90px;
  }

  .svgWrapper > svg {
    transform: scale(0.6);
  }

  .videoWrapper > h1 {
    font-size: 29px;
  }
}

@media (max-width: 1000px) {
  .wrapper {
    display: grid;
    justify-content: center;
  }

  .videoWrapper {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    align-content: start;
  }

  .iframe {
    width: 100%;
  }
}

@media (max-width: 560px) {
  .videoWrapper {
    width: calc(100% - 60px);
  }

  .videoBgWrapper {
    border-radius: 50px;
  }

  .videoWrapper > h1 {
    font-size: 17px;
    line-height: 17px;
  }

  .svgWrapper > svg {
    transform: scale(0.4);
  }
}

@media (max-width: 300px) {
  .videoBgWrapper {
    border-radius: 35px;
  }

  .videoWrapper > h1 {
    font-size: 17px;
  }
}

@media (max-height: 900px) and (min-width: 600px) and (max-width: 1000px) {
  .wrapper {
    height: 200vh;
  }
}

@media (max-height: 500px) and (min-width: 600px) and (max-width: 1000px) {
  .wrapper {
    height: 250vh;
  }
}

@media (max-height: 500px) and (min-width: 600px) and (max-width: 1000px) {
  .wrapper {
    height: 350vh;
  }
}

@keyframes glow {
  to {
    box-shadow: 0 0 20px 10px white;
  }
}
